<template>
  <button :class="{nosku:sku=='NA'}" class='znet-btn' @click.stop="skuOnClick"><img :style="{width:skuWidth}" class='znet-img' :src="znetImage"></button>
</template>

<script>
import { ref, computed } from 'vue'
export default {
  props: {
    sku: { type: String, default: 'NA' },
    skuWidth: { type: String, default: '142px' }
  },
  setup (props) {
    const skuActiveImage = require('@/assets/znet-active.png')
    const skuMissingImage = require('@/assets/znet-missing.png')
    const znetImage = ref('')

    const skuOnClick = () => {
      if (props.sku !== 'NA') { window.location.href = `znet://actions/skuCrossRef?sku=${props.sku}` }
    }
    znetImage.value = computed(() => {
      return props.sku === 'NA' ? skuMissingImage : skuActiveImage
    }).value

    return { skuOnClick, znetImage }
  }
}
</script>

<style lang='scss'>
    .znet-btn{
        background-color: #0000;
        border: none;
        padding: 0;
        margin: 0;
        line-height: 0;
            height: auto;
        cursor:pointer;
        &.nosku{
          opacity: .3;
          cursor:default;
        }
        .znet-img{
          height: auto;
        }
    }
</style>
