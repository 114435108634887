<template>

  <div class="fixed-header" @scroll='pageScrolled' :class="{'scrolled-down':scrolledDown}">
      <div class="fixed-header-content">
        <h1>{{ title }}</h1>

          <div class='seconday'><slot/></div>

      </div>
    </div>

</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
export default {
  props: { title: String },
  setup () {
    const scrolledDown = ref(false)
    const scrollHandler = () => {
      scrolledDown.value = window.scrollY > 0
    }
    onMounted(() => {
      window.addEventListener('scroll', scrollHandler)
      scrollHandler()
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', scrollHandler)
    })
    return { scrolledDown }
  }
}
</script>

<style lang="scss">
.fixed-header {
    width: 100%;
    background-color: $fixed-header-bg-color;
    z-index:8;
    height:$fixed-header-height;
    position:fixed;
    left: 0;
    &.scrolled-down{
    filter: drop-shadow(0px 1px 3px rgba(0,0,0,.2));
    }
    .fixed-header-content{
      padding: 0 28px;
      max-width: 1024px;
      width: 100%;
      margin: 0 auto;
      text-align:left;
      h1{
        margin-top: 30px;
        margin-bottom: 20px;
        line-height: 25px;
        text-transform: uppercase;
      }
    }
    .hide-show-diagram {
      color: red;
    }
  }
</style>
