<template>
  <div class="part-tile" :class="'hotspot-'+((partData?.assets?.length > 0 && partData.assets[0].AssetItemRef) ? partData.assets[0].AssetItemRef : '0')">
    <div class="hotspot-number" :data-hotspot-id='partData.assets[0].AssetItemRef' v-if='partData.assets?.length > 0 && partData.assets[0].AssetItemRef'>{{partData.assets[0].AssetItemRef}}</div>
    <div class="inner">
      <div class="title">{{partData.product_part?.product_attributes?.find(attr => attr.AttributeID === 'Title')?.Text}}</div>
      <div class="details">
        <div class="media">
          <img :src="this.getTileImage()" />
        </div>
        <div class="description">
          <div class="specs" style="display:none">
            <div class="spec">
              <span class="label"> Position: </span>
              <span class="value"> Right </span>
            </div>
            <div class="spec">
              <span class="label"> CARB Executive Order: </span>
              <span class="value"> D-195-120 </span>
            </div>
          </div>
          <div class="notes">
            <span class="label">Notes:</span>
            <div v-for="(note, index) in partData.qual" :key="index"
             class="note">
              <i class="fas icon-fa-arrow-right" />
              {{note.text}}
            </div>
          </div>
        </div>
      </div>
      <div class="purchase">
        <div class="skus">
          <div class="sku">
            <span class="label">AutoZone</span>
            <span class="value">#{{(partData?.client_data?.length > 0 && partData.client_data[0].PartNumber) ? partData.client_data[0].PartNumber : 'NA'}}</span>
          </div>
          <div class="sku" >
            <span class="label">BRE</span><span class="value">#{{partData?.PartNumber || 'NA'}}</span>
          </div>
        </div>
        <div class="add-to-quote-cta">
          <ExternalClientPdpButton :title="cmsExternalClientPdpButton" :url="(partData?.client_data?.length > 0 && partData.client_data[0].Urls?.length > 0 && partData.client_data[0].Urls[0]) ? partData.client_data[0].Urls[0] : 'NA'" v-if="!showZnetButton"/>
          <Znet :sku="(partData?.client_data?.length > 0 && partData.client_data[0].PartNumber) ? partData.client_data[0].PartNumber : 'NA'" v-if="showZnetButton"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalClientPdpButton from '@/components/buttons/ExternalClientPdpButton.vue'
import Znet from '@/components/buttons/Znet.vue'
import { useGetters } from '@/lib/helpers'
import { ref } from 'vue'

export default {
  props: { partData: Object },
  components: { ExternalClientPdpButton, Znet },
  setup (props) {
    const showZnetButton = ref(true)
    const { cmsExternalClientPdpButton } = useGetters(['cmsExternalClientPdpButton'])
    const getTileImage = () => {
      // console.log(JSON.stringify(props.partData))
      let tileImage
      const defaultImg = require('@/assets/no-image-available.jpg')
      const heroIndex = ('digital_assets' in props.partData) ? props.partData.digital_assets.findIndex(_asset => _asset.AssetType === 'P04') : '-1'

      if (heroIndex >= 0) tileImage = props.partData.digital_assets[heroIndex].URI
      tileImage = tileImage || defaultImg

      return tileImage
    }
    const zNetClicked = (e) => {
    }
    return { cmsExternalClientPdpButton, showZnetButton, getTileImage, zNetClicked }
  }
}
</script>

<style lang="scss">
$hotSpotContainerSize: 35px;
.part-tile {
  width: 480px;
  height: 370px;
  text-align: left;
  position: relative;
  cursor:pointer;
  margin: 0 10px;
  .hotspot-number {
    position: absolute;
    background-color: #656768;
    color: white;
    width: $hotSpotContainerSize;
    height: $hotSpotContainerSize;
    line-height: $hotSpotContainerSize;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    z-index: 5;
    border-radius: 5px;
  }
  .inner {
    padding: 23px;
    margin: 15px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    outline: #656768 solid 1px;
    display: flex;
    position: absolute;
    border-radius: 10px;
    .title {
      @include mf-bold(18px);
    }
    .details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      max-height: 170px;
      padding-top: 20px;
      overflow: hidden;
      .media {
        width: 140px;
        img{
          max-width: 100%;
        }
      }
      .description {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        width: 190px;
        .label {
          font-weight: bold;
        }
        .specs {
          width: 190px;
          .spec {
            margin-bottom: 10px;
          }
        }
        .notes {
          display: flex;
          flex-direction: column;
          .note{
            margin-top:5px;
            position:relative;
          padding-left:15px;
          font-size: 12px !important;
            i{
              position:absolute;
              left: 0;
              font-size: 8px;
              top: 4px;
            }
          }
        }
      }
    }
    .purchase {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: $mf-border-color solid thin;
      align-items:flex-end;
      text-align: center;
      padding-top: 32px;
      flex-grow: 1;
      max-height: 40px;
      margin-top: auto;
      @include mf-bold(13px);
      font-style: italic;
      .sku {
        text-align: left;
        padding-bottom:3px;
        .label,
        .value {
          display: inline-block;
          font-size: 14px;
        }
        .label {
          width: 62px;
          text-align: right;
          margin-right: 10px;
        }
      }
    }
  }
   &.hotspot-selected{
    .inner{
      outline: var(--btn-bg-color) solid 3px;
    }
    .hotspot-number {
      background-color: var(--btn-bg-color) !important;;
    }
  }
}
</style>
