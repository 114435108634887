<template>
  <div class="hotspot-container" v-show="show">
    <div class="hotspot-asset" v-html="hotspotAsset"></div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { hotSpots } from '@/lib/HotSpots'
import { ref, watchEffect } from 'vue'
import axios from 'axios'
import gsap from 'gsap'

const hotspotUrlBase =
  'https://mfcdnstorage.blob.core.windows.net/bre-diagrams/'

export default {
  props: {
    hotspotData: { type: [Boolean, String], default: null },
    show: { type: Boolean, default: false }
  },
  emits: ['HOTSPOT_DIAGRAM_LOADED', 'HOTSPOTS_VIEW_INITED'],

  setup (props, { emit }) {
    const hotspotAsset = ref('')
    const requestHotspot = async () => {
      const hotspotUrl = hotspotUrlBase + props.hotspotData + '.svg'
      hotspotAsset.value = await axios
        .get(hotspotUrl)
        .then((response) => {
          emit('HOTSPOT_DIAGRAM_LOADED')
          return response.data
        })
        .catch((error) => {
          console.log(
            'Error:: Loading hotspot asset failed',
            hotspotUrl,
            error.message
          )
          return false
        })
      // todo:: update tween only when loaded
      gsap.set('.hotspot-container', { autoAlpha: 0 })
      gsap.to('.hotspot-container', {
        autoAlpha: 1,
        duration: 0.5,
        onComplete: hotSpots.init
      })
      emit('HOTSPOTS_VIEW_INITED')
    }
    watchEffect(() => {
      if (props.hotspotData) {
        requestHotspot()
      }
    })

    return { hotspotAsset }
  }
}
</script>

<style lang="scss">
.hotspot-container {
  max-width: 1024px;
  display: flex;
  height: 400px;
  border-bottom: $mf-border-color solid thin;
  width: 100%;
  padding-bottom: 30px;
  .hotspot-asset {
    max-width: 700px;
    justify-content: center;
    margin: 0 auto;
    rect{
      fill: var(--btn-bg-color) !important;
      cursor:pointer;
    }
    tspan {
      cursor: pointer;
      font-family: "interstate" !important;
      @include mf-bold(40px);
    }
    #DiagramName tspan {
      fill: black !important;
      @include mf-bold(60px);
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
